<template>
  <div class="space-y-4 py-6 flex flex-col h-full px-8">
    <div class="flex flex-row items-center pb-2">
      <h4 class="h4 pr-5">{{ monitoringJob.name }}</h4>
      <p
        class="px-3 py-1 rounded-md font-bold text-sm"
        :class="monitoringJob.monitoringType === 'brand' ? 'blue-bg-3 blue-text-color' : 'red-bg-3 red-text-color'"
      >
        {{ monitoringJob.monitoringType === 'brand' ? 'Brand' : 'Generic' }}
      </p>
    </div>
    <div class="space-y-6 pt-2">
      <page-tabs
        v-if="monitoringJob.monitoringType === 'brand'"
        :tabs="pageTabs"
        :selected-tab="selectedTab"
        class="border-b-2 border-neutral-50"
      >
        <div
          v-for="tab in pageTabs"
          :key="tab.value"
          class="cursor-pointer z-10 duration-300 ease-linear"
          :class="{
            'border-bb-brand-purple text-bb-brand-purple': selectedTab === tab.value,
            'text-bb-text-default border-neutral-50 hover:border-neutral-100': selectedTab !== tab.value,
          }"
          style="border-bottom-width: 2px; margin-bottom: -2px"
        >
          <div
            class="px-6 flex gap-2 items-center pb-2"
            @click="selectTab(tab.value)"
          >
            <p>
              {{ tab.label }}
            </p>
          </div>
        </div>
      </page-tabs>
    </div>
    <div class="base-scrollbar overflow-y-auto flex-grow">
      <!-- Content based on the selected tab -->
      <div v-if="selectedTab === 'general'">
        <SettingsGeneral
          :selected-keywords="selectedKeywords"
          @update:selectedKeywords="selectedKeywords = $event"
        />
      </div>
      <div v-if="selectedTab === 'infringements'">
        <settings-infringement
          :selected-keywords="selectedKeywords"
          @update:selectedKeywords="selectedKeywords = $event"
        />
      </div>

      <div v-if="selectedTab === 'smart-activation'">
        <settings-smart-activation
          :selected-keywords="selectedKeywords"
          @update:selectedKeywords="updateSelectedKeywords"
        />
      </div>
    </div>
    <div class="flex flex-row justify-between pt-5">
      <merge-button-round
        button-type="secondary"
        class="w-42"
        @click="CloseModal"
        >Close</merge-button-round
      >
      <merge-button-round
        class="w-42"
        @click="saveSettings"
        >Save</merge-button-round
      >
    </div>
  </div>
</template>

<script>
import SettingsGeneral from '../../settings-views/SettingsGeneral.vue'
import SettingsInfringement from '../../settings-views/SettingsInfringement.vue'
import SettingsSmartActivation from '../../settings-views/SettingsSmartActivation.vue'
import PageTabs from '@/components/shared/PageTabs.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

const mockMonitoringJob = {
  name: 'Group Name 1',
  monitoringType: 'brand',
}

export default {
  name: 'SelectedMonitoringSettings',
  components: {
    SettingsGeneral,
    SettingsInfringement,
    SettingsSmartActivation,
    PageTabs,
    MergeButtonRound,
  },
  data() {
    return {
      pageTabs: [
        { label: 'General', value: 'general' },
        { label: 'Infringements', value: 'infringements' },
        { label: 'Smart Activation', value: 'smart-activation' },
      ],

      //TODO: Replace this with the actual data
      selectedKeywords: [
        { label: 'Keyword 1', value: 'keyword-1', status: 'DISABLED', selected: true, smartActivation: true },
        { label: 'Keyword 2', value: 'keyword-2', status: 'DISABLED', selected: true, smartActivation: true },
        { label: 'Keyword 3', value: 'keyword-3', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 4', value: 'keyword-4', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 5', value: 'keyword-5', status: 'DISABLED', selected: false, smartActivation: true },
        { label: 'Keyword 6', value: 'keyword-6', status: 'DISABLED', selected: false, smartActivation: true },
      ],
      selectedTab: 'general',
      monitoringJob: mockMonitoringJob,
    }
  },
  methods: {
    selectTab(tabValue) {
      this.selectedTab = tabValue
    },
    saveSettings() {
      //TODO: Remove console.log and add actual logic
      console.log('Settings saved')
    },
    CloseModal() {
      this.$emit('close-modal')
    },
    updateSelectedKeywords(updatedKeywords) {
      this.selectedKeywords = [...updatedKeywords]
    },
  },
}
</script>

<style scoped lang="scss"></style>
