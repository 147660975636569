<template>
  <div class="space-y-5 divide-y">
    <div class="grid grid-cols-3 pt-2">
      <div class="flex flex-col">
        <p class="font-medium">Campaign</p>
        <p class="text-bb-neutral-gray pt-1">Your brand campaign</p>
      </div>
      <search-input
        id="select-campaign"
        class="pb-8 z-1000 absolute w-90 col-span-2"
        disabled="disabled"
        input-id="select-campaign"
        placeholder="Brightbid Brand Campaign 1"
        options-max-width="max-w-120"
        truncate-width="w-96"
      />
    </div>

    <div class="grid grid-cols-3">
      <div class="flex flex-col">
        <p class="font-medium pt-5">Keywords</p>
        <p class="text-bb-neutral-gray">Your set of keywords your monitor</p>
      </div>
      <div class="py-3 col-span-2">
        <top-keywords-table
          :keywords="keywords"
          :selected-keywords="selectedKeywords"
          class="max-w-120"
          @add-keyword="addKeyword"
        />
        <p
          v-if="selectedKeywords.length === 0"
          class="text-xs text-bb-red-error"
        >
          Please select at least one brand term.
        </p>
        <div
          v-else
          class="flex flex-wrap gap-x-2 gap-y-2 pt-2"
        >
          <chip-tag
            v-for="chip in selectedKeywords"
            :key="chip.value"
            :chip="chip"
            :fully-clickable="false"
            :expandable="false"
            class="bg-bb-secondary-purple text-bb-brand-purple py-0.5 font-medium rounded-md border-bb-purple-100 border pl-2 flex gap-2 items-center w-fit shadow-bb-shadow"
            @click-right-icon="removeKeyword(chip)"
          >
            <template #right-icon>
              <span><ic-cross class="w-4 h-4" /></span>
            </template>
          </chip-tag>
        </div>
        <div>
          <p class="text-sm mt-8">Missing a brand term? Add it here.</p>
          <text-input
            v-model="brandedTerm"
            class="w-96"
            input-name="brandedTerm"
            input-id="brandedTerm"
            has-icon
            padding-right="pr-20"
            placeholder="Brand term..."
            :error="duplicateBrandedTerm"
            :error-message="duplicateBrandedTerm ? 'This term already exists. Please enter a different term.' : ''"
            @keydown.enter="addBrandedTerms"
            @keydown="duplicateBrandedTerm = false"
          >
            <template #suffix>
              <div class="h-10 w-24 absolute right-0 top-0 p-2">
                <div
                  class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                  @click="addBrandedTerms"
                >
                  <ic-plus :size="16" />
                  <p>Add</p>
                </div>
              </div>
            </template>
          </text-input>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 items-center pt-5 pb-10">
      <div class="flex flex-col">
        <p class="font-medium">Location</p>
        <p class="text-bb-neutral-gray">Country you are monitoring</p>
      </div>

      <ul class="flex flex-wrap gap-x-4 col-span-2">
        <li
          v-for="selectedItem in locations"
          :key="selectedItem.value"
          class="h5 font-normal flex flex-row items-center"
        >
          <img
            :src="`/content/flags/${selectedItem.value}.svg`"
            alt=""
            class="w-5 h-5 mx-1 rounded-md"
          />
          {{ selectedItem.label }}
        </li>
      </ul>
    </div>
    <div class="grid grid-cols-3 py-5">
      <div class="flex flex-col">
        <p class="font-medium">Manage preferences</p>
        <p class="text-bb-neutral-gray">Stop or delete monitoring group</p>
      </div>
      <div class="space-x-4 flex flex-col sm:flex-row items-center col-span-2">
        <merge-button-round
          button-type="secondary"
          @click="pauseMonitoring"
        >
          <template #left-icon>
            <ic-pause
              :size="16"
              class="my-auto"
            />
          </template>
          Pause Monitoring</merge-button-round
        >
        <merge-button-round
          button-type="quinary"
          class="font-medium"
          @click="deleteMonitoring"
        >
          Delete Monitoring
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import TopKeywordsTable from '../components/TopKeywordsTable.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import ChipTag from '@/views/site/search/competitor_monitoring_v2/components/ChipTag.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

import IcPause from '@/components/icon/brightbid/ic-pause.vue'
import IcCross from '@/components/icon/ic-cross.vue'
import IcPlus from '@/components/icon/ic-plus.vue'

export default {
  name: 'SettingsGeneral',
  components: {
    TopKeywordsTable,
    SearchInput,
    ChipTag,
    MergeButtonRound,
    TextInput,
    IcPause,
    IcCross,
    IcPlus,
  },
  props: {
    selectedKeywords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locations: [{ label: 'Sweden', value: 'se' }],
      keywords: [],
      duplicateBrandedTerm: false,
      localSelectedKeywords: [...this.selectedKeywords],
      selectedLocation: { label: 'Sweden', value: 'se' },
      brandedTerm: '',
    }
  },
  mounted() {
    this.fetchKeywords()
  },
  methods: {
    fetchKeywords() {
      this.keywords = [
        { keyword_text: 'Keyword 1', search_volume: 100, clicks: 50 },
        { keyword_text: 'Keyword 2', search_volume: 200, clicks: 100 },
        { keyword_text: 'Keyword 3', search_volume: 300, clicks: 150 },
        { keyword_text: 'Keyword 4', search_volume: 400, clicks: 200 },
        { keyword_text: 'Keyword 5', search_volume: 500, clicks: 250 },
        { keyword_text: 'Keyword 6', search_volume: 600, clicks: 300 },
      ]
    },
    addKeyword(keyword) {
      const exists = this.localSelectedKeywords.some(k => k.value === keyword.keyword_text)
      if (!exists) {
        this.localSelectedKeywords.push({
          label: keyword.keyword_text,
          value: keyword.keyword_text,
          smartActivation: false,
        })
        this.$emit('update:selectedKeywords', this.localSelectedKeywords)
      }
    },

    removeKeyword(keyword) {
      const index = this.localSelectedKeywords.findIndex(k => k.value === keyword.value)
      if (index !== -1) {
        this.localSelectedKeywords.splice(index, 1)
        this.$emit('update:selectedKeywords', this.localSelectedKeywords)
      }
    },

    addBrandedTerms() {
      if (this.brandedTerm.trim() === '') {
        return
      }

      const keywordExistsInSelected = this.localSelectedKeywords.some(k => k.value === this.brandedTerm)
      const keywordExistsInData = this.keywords.some(k => k.keyword_text === this.brandedTerm)

      if (keywordExistsInSelected || keywordExistsInData) {
        this.duplicateBrandedTerm = true
        return
      }

      this.localSelectedKeywords.push({
        label: this.brandedTerm,
        value: this.brandedTerm,
        smartActivation: false,
      })

      this.brandedTerm = ''
      this.$emit('update:selectedKeywords', this.localSelectedKeywords)
      this.duplicateBrandedTerm = false
    },
    pauseMonitoring() {
      console.log('pause monitoring')
    },
    deleteMonitoring() {
      console.log('delete monitoring')
    },
    selectLocation(location) {
      this.selectedLocation = location
      console.log('Location selected:', location)
    },
  },
}
</script>
