<template>
  <div>
    <slot
      name="inputName"
      :input-name="inputName || ''"
    >
      <p v-if="inputName">{{ inputName }}</p>
    </slot>
    <div
      v-click-outside="closeDropdown"
      class="relative"
    >
      <div
        class="relative"
        :class="triggerStyle"
        @click="openDropdown"
      >
        <!--Customizable -->
        <!--This can be a button, text input, or any other element -->
        <slot
          name="trigger"
          :selected-item="selectedItem"
        >
          <p
            v-if="selectedItem && selectedItem?.value"
            class="whitespace-no-wrap"
          >
            {{ selectedItem?.label }}
          </p>
        </slot>

        <div
          v-if="showDropdownArrow"
          :class="arrowStyle"
        >
          <slot
            name="dropdown-arrow"
            :direction="isDropdownVisible"
          >
            <dropdown-arrow :direction="isDropdownVisible ? 'up' : 'down'" />
          </slot>
        </div>
      </div>

      <transition name="dropdown">
        <div
          v-if="isDropdownVisible"
          class="overflow-y-auto dropdown-scrollbar absolute z-20"
          :class="[dropdownStyle, position]"
        >
          <!--Component-->
          <slot
            name="dropdown-component"
            :options-count="options.length"
          ></slot>

          <!--Empty State-->
          <div v-if="options.length === 0">
            <slot name="empty-state">
              <p class="p-2 whitespace-no-wrap">No available options...</p>
            </slot>
          </div>

          <!--Options-->
          <div
            v-for="item in options"
            v-else
            :key="item.value"
          >
            <div @click="selectItem(item)">
              <!--Customizable -->
              <!-- Use margin-top to adjust the vertical position -->
              <slot
                name="option"
                :option-data="item"
              >
                <p class="p-2 hover:bg-neutral-100 duration-300 ease-linear cursor-pointer">{{ item.label }}</p>
              </slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow.vue'

export default {
  name: 'BaseDropdown',
  components: {
    DropdownArrow,
  },
  props: {
    triggerStyle: {
      type: String,
      default: 'h-8 w-50 border rounded-lg flex items-center whitespace-no-wrap px-2',
    },
    dropdownStyle: {
      type: String,
      default: 'mt-10 w-full max-h-48 rounded-lg bg-white',
    },
    arrowStyle: {
      type: String,
      default: 'absolute right-0 mx-2',
    },
    options: {
      type: Array,
      default: () => [],
    },
    showDropdownArrow: {
      type: Boolean,
      default: true,
    },
    inputName: {
      type: String,
      default: '',
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    position: {
      type: String,
      default: ' top-0 left-0',
    },
    hasDropdownComponent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownVisible: false,
    }
  },
  methods: {
    openDropdown(event) {
      event.stopPropagation()
      if (this.disabled) return
      this.isDropdownVisible = !this.isDropdownVisible
    },
    closeDropdown() {
      this.isDropdownVisible = false
      this.$emit('close-dropdown')
    },
    selectItem(item) {
      this.isDropdownVisible = false
      this.$emit('select-item', item)
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown-enter-active,
.dropdown-leave-active {
  transition: max-height 0.2s ease-in-out;
}
.dropdown-enter,
.dropdown-leave-to {
  max-height: 0;
}
</style>
