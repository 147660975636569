<template>
  <div class="space-y-5">
    <div class="grid grid-cols-3 gap-x-10 pt-2 border-b pb-5">
      <div class="flex flex-col">
        <p class="font-medium">Brand Terms</p>
        <p class="text-bb-neutral-gray pt-1">Mark brand terms that will trigger infringement alerts</p>
      </div>
      <div class="col-span-2">
        <div class="flex flex-row gap-x-2 px-10 pb-3 justify-between">
          <p class="font-medium">Keywords</p>
          <p class="font-medium">Brand Protection</p>
        </div>
        <div class="flex flex-col justify-center gap-y-3 px-10">
          <KeywordList
            :keywords="localSelectedKeywords"
            @update-status="handleInfringement"
          />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 gap-x-10 items-center pt-1 pb-10">
      <div class="flex flex-col">
        <p class="font-medium">Location</p>
        <p class="text-bb-neutral-gray">Country you are monitoring</p>
      </div>
      <ul class="flex flex-wrap gap-x-4 col-span-2 px-9">
        <li
          v-for="selectedItem in locations"
          :key="selectedItem.value"
          class="h5 font-normal flex flex-row items-center"
        >
          <img
            :src="`/content/flags/${selectedItem.value}.svg`"
            alt=""
            class="w-5 h-5 mx-1 rounded-md"
          />
          {{ selectedItem.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import KeywordList from '@/views/site/search/competitor_monitoring_v2/components/KeywordList.vue'

export default {
  name: 'SettingsInfringement',
  components: {
    KeywordList,
  },
  props: {
    selectedKeywords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locations: [{ label: 'Sweden', value: 'se' }],
      localSelectedKeywords: [...this.selectedKeywords],
      selectedLocation: { label: 'Sweden', value: 'se' },
      trademark: '',
      duplicateTrademark: false,
    }
  },
  watch: {
    selectedKeywords(newKeywords) {
      this.localSelectedKeywords = [...newKeywords]
    },
  },
  methods: {
    pauseMonitoring() {
      console.log('pause monitoring')
    },
    deleteMonitoring() {
      console.log('delete monitoring')
    },
    handleInfringement(keyword) {
      const updatedKeywords = this.localSelectedKeywords.map(k =>
        k.value === keyword.value ? { ...k, selected: !k.selected } : k,
      )
      this.localSelectedKeywords = updatedKeywords
      this.$emit('update:selectedKeywords', updatedKeywords)
    },
  },
}
</script>
